/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { TranslateService } from '@ngx-translate/core';
import { EncryptionService } from './encryption.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  constructor(
    private _encyrption: EncryptionService,
    private translate: TranslateService
  ) {}
  requestPermission(): void {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
      .then((currentToken: any) => {
        if (currentToken) {
          this._encyrption.encryptLocalStorage(currentToken, 'fcmToken');
        } else {
          alert(this.translate.instant('common.fcmNotification'));
        }
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  listenForFrontMessages(): void {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      const message = payload;
      if (message.notification) {
        const notificationOptions = {
          body: message.notification.body || '',
          icon: '',
          data: message
        };
        // Display notification using service worker
        this.displayNotification(
          message.notification.title || '',
          notificationOptions
        );
      }
    });
  }
  private displayNotification(
    title: string,
    options: NotificationOptions
  ): void {
    // Check for service worker registration
    navigator.serviceWorker
      .getRegistration('/firebase-cloud-messaging-push-scope')
      .then(registration => {
        if (registration) {
          // Show notification
          registration.showNotification(title, options);
        } else {
          console.error('Service worker registration not found.');
        }
      });
  }
}
