<header class="site_header">
  <div class="container">
    <figure routerLink="/" class="logo">
      <img src="{{ aasetsConst.Logo }}" alt="logo" />
    </figure>
    <div class="header_rht">
      @if (!isLoggedIn) {
        <button class="btn btn_primary br" (click)="loginModal()">
          {{ 'Login' | translate }}
        </button>
      } @else {
        <ul class="listing-box">
          <li
            routerLink="/bookings"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ 'Bookings' | translate }}
          </li>
          <li routerLink="/partners" routerLinkActive="active">
            {{ 'MyPartners' | translate }}
          </li>
          <li routerLink="/packages" routerLinkActive="active">
            {{ 'Packages' | translate }}
          </li>
        </ul>
        <button class="profile_menu_btn" mat-button [matMenuTriggerFor]="menu">
          <figure>
            <img
              src="{{
                profile?.image ? profile?.image : aasetsConst.userProfile
              }}"
              alt="icon" />
          </figure>
          <figcaption>
            <mat-icon>arrow_drop_down</mat-icon>
          </figcaption>
        </button>
        <mat-menu #menu="matMenu" class="dropbox" xPosition="before" yPosition="below">
          <button mat-menu-item routerLink="/profile">
            <mat-icon>person</mat-icon> {{ 'MyProfile' | translate }}
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon> {{ 'Logout' | translate }}
          </button>
        </mat-menu>
      }
    </div>
  </div>
</header>
