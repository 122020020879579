import { Component, OnDestroy, OnInit } from '@angular/core';
import { assets } from '../../core/const/assetsConst';
import { SearchComponent } from '../components/search/search.component';
import { Router, RouterModule } from '@angular/router';
import { LoginComponent } from '../../modals/auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { SpinnerService } from '../../services/spinner.service';
import { LoginResponse, logoutResponse } from '../../models/auth.model';
import { responseStatus } from '../../core/const/responseStatus.const';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'xplora-luxe-header',
  standalone: true,
  imports: [
    SearchComponent,
    RouterModule,
    MatMenuModule,
    TranslateModule,
    MatIconModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  protected aasetsConst = assets;
  protected isHomePage: boolean = false;
  protected isLoggedIn = false;
  private destroy: Subject<boolean> = new Subject<boolean>();
  protected profile: LoginResponse | null = null;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _authService: AuthService,
    private spinnerService: SpinnerService
  ) {
    this._authService.isLoggedIn.pipe(takeUntil(this.destroy)).subscribe({
      next: (res: boolean) => {
        this.isLoggedIn = res;
        if (_authService.isAuthenticated() && !res) {
          this.getProfile();
        }
      }
    });
  }

  ngOnInit(): void {
    this.checkIfHomePage();
  }

  loginModal(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: ['modal', 'auth_modal']
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  getProfile(): void {
    this.spinnerService.addToLoader('getProfile');
    this._authService
      .getProfile()
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (res: LoginResponse) => {
          this.spinnerService.removeFromLoader('getProfile');
          this.profile = res;
          this._authService.isLoggedIn.next(true);
          this._authService.profileDetails.next(res);
        },
        error: () => {
          this.spinnerService.removeFromLoader('getProfile');
        }
      });
  }

  checkIfHomePage(): void {
    this.router.events.subscribe(() => {
      this.isHomePage = this.router.url === '/' || this.router.url === '/home';
    });
  }

  logout(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._authService.logoutAlert().then((res: any) => {
      if (
        Object.prototype.hasOwnProperty.call(res, 'value') &&
        res.isConfirmed === true
      ) {
        this.spinnerService.addToLoader('logout');
        this._authService
          .logout()
          .pipe(takeUntil(this.destroy))
          .subscribe({
            next: (res: logoutResponse) => {
              if (res.statusCode === responseStatus.SUCCESS) {
                localStorage.removeItem(environment.storageKey);
                this.spinnerService.removeFromLoader('logout');
                this._authService.isLoggedIn.next(false);
                this.router.navigate(['/']);
              }
            },
            error: () => {
              this.spinnerService.removeFromLoader('logout');
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.complete();
    this.destroy.unsubscribe();
  }
}
