import { Routes } from '@angular/router';
import { AuthGuardFxn } from './core/guards/auth.guard';
import { RestaurantVerificationComponent } from './restaurant-verification/restaurant-verification.component';
import { SetPasswordComponent } from './set-password/set-password.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: '',
    canActivate: [AuthGuardFxn],
    loadChildren: () => import('./modules/afterLoginPages/afterLoginPages.routes').then(m => m.routes)
  },
  {
    path: 'restaurantVerification/:id',
    component: RestaurantVerificationComponent
  },
  {
    path: 'setPassword',
    component: SetPasswordComponent
  }
];
