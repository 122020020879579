import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { BaseClass } from '../shared/class/baseClass';
import { assets } from '../core/const/assetsConst';
import { passwordFormErrors } from '../core/const/commonErrors.const';
import { AuthService } from '../services/auth.service';
import { REGEX } from '../core/const/regex.constant';
import { MustMatch, passwordValidatorHelper } from '../core/helpers/validation.helper';

@Component({
  selector: 'xplora-luxe-set-password',
  standalone: true,
  imports: [MatFormFieldModule, MatIconModule, RouterModule, MatInputModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.scss'
})
export class SetPasswordComponent extends BaseClass
  implements OnInit, OnDestroy {
  hiddenNewPassowrdVisibility = true;
  hiddenConfirmPasswordVisibility = true;
  submitted = false;
  setPasswordFormGroup: FormGroup;
  destroy: Subject<boolean> = new Subject<boolean>();
  assetsConst = assets;
  formErrors = passwordFormErrors;
  vendorId: string | null = null;


  constructor(
    injector: Injector,
  private _authService: AuthService
  ) {
    super(injector);
    this.setPasswordFormGroup = this.formBuilder.group(
      {
        newPassword: [
          '',
          [Validators.required, Validators.pattern(REGEX.PASSWORD_PATTERN)]
        ],
        confirmPassword: ['', [Validators.required]]
      },
      { validators: MustMatch('newPassword', 'confirmPassword') }
    );
    this.onControlValueChange();
  }

  onControlValueChange(): void {
    const passwordControl = this.setPasswordFormGroup.get('newPassword');
    if (passwordControl) {
      passwordControl.setValidators([
        Validators.required,
        this.passwordValidator()
      ]);
    }
  }

  passwordValidator = (): ValidatorFn => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fName = null;
      const userEmail = 'xplora@yopmail.com';
      return passwordValidatorHelper(control, {
        firstName: fName,
        userEmail
      });
    };
  };

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.vendorId = params.get('vendorId');
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.setPasswordFormGroup.valid) {
      const payload = {
        vendorId: this.vendorId,
        ...this.setPasswordFormGroup.value
      };
      this.spinnerService.addToLoader('resetPassword');
      this._authService.resetPassword(payload)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: () => {
            this.spinnerService.removeFromLoader('resetPassword');
            this.toasterService.successToastr(
              this.translateService.instant('resetPassSuccess')
            );
            localStorage.clear();
            this.router.navigate(['/']);
          },
          error: () => {
            this.spinnerService.removeFromLoader('resetPassword');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}


