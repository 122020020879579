import { Component, Inject, InjectionToken, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { assets } from './core/const/assetsConst';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AuthService } from './services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { MessagingService } from './services/messaging.service';

@Component({
  selector: 'xplora-luxe-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    TranslateModule,
    HttpClientModule,
    NgxUiLoaderModule
  ],
  providers: [TranslateService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {

  protected assetsConst = assets;
  private destroy: Subject<boolean> = new Subject();
  protected isLoggedIn = false;
  title = 'xplora-rest';
  constructor(public translate: TranslateService, private _authService: AuthService, private router: Router, private _messagingService: MessagingService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this._authService.isLoggedIn.pipe(takeUntil(this.destroy)).subscribe({next: (res: boolean) => {
      this.isLoggedIn = res;
    }});
    if (isPlatformBrowser(this.platformId)) {
      this._messagingService.requestPermission();
      this._messagingService.listenForFrontMessages();
      this.translate.get('notificationAlert').subscribe(() => {
        if (Notification.permission === 'denied' && this.translate.store.translations) {
          alert(this.translate.instant('notificationAlert'));
        }
      });
    }
  }

  isPublicRoute(): boolean {
    const publicRoutes = ['/restaurantVerification', '/setPassword'];
    return publicRoutes.some(route => this.router.url.includes(route));
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
