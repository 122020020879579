<xplora-luxe-header />

@if (isLoggedIn || isPublicRoute()) {
  <router-outlet></router-outlet>
} @else {
  <div class="logo-main">
    <figure>
      <img [src]="assetsConst.Logo" alt="logo" />
    </figure>
  </div>
}

<ngx-ui-loader fgsColor="#00649e"></ngx-ui-loader>

