<section class="auth_sc">
  <div class="auth_flx gap_m restaurant-pg">
    <div class="auth_left">
    </div>
    <div class="auth_rht">
  <h1>{{ 'WelcomeAsRestaurant' | translate }}</h1>
  <p>{{ 'ClickOkToReceiveCredentials' | translate }}</p>
  <button class="btn btn_primary" type="button" (click)="confirmJoin()">{{ 'OK' | translate }}</button>
</div>
  </div>
</section>
