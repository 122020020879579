<div class="auth_sc">
  <figure class="logo"><img src="{{ assetsConst.Logo }}" alt="logo" /></figure>
    <form
      class="auth_form"
      [formGroup]="setPasswordFormGroup"
      (ngSubmit)="onSubmit()">
      <h1 class="text_
      center">{{ 'setPassword' | translate }}</h1>
      <div class="form_group icon_field w_100">
        <mat-label>{{ 'EnterPassword' | translate }}</mat-label>
        <div class="position_relative">
          <mat-form-field appearance="fill">
            <input
              matInput
              placeholder="{{ 'EnterPassword' | translate }}"
              class="form_control"
              formControlName="newPassword"
              [type]="hiddenNewPassowrdVisibility ? 'password' : 'text'" />
          </mat-form-field>
          <span
            mat-icon-button
            matSuffix
            (click)="hiddenNewPassowrdVisibility = !hiddenNewPassowrdVisibility"
            [attr.aria-mat-label]="'Hide password'"
            [attr.aria-pressed]="hiddenNewPassowrdVisibility"
            class="pass_ico">
            <mat-icon>{{
              hiddenNewPassowrdVisibility ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </span>
          @if (submitted) {
            @for (err of formErrors; track $index) {
              @if (
                setPasswordFormGroup.controls['newPassword'].hasError(err.key)
              ) {
                <mat-error>{{ err.message | translate }}</mat-error>
              }
            }
          }
        </div>
      </div>
      <div class="form_group icon_field w_100">
        <mat-label>{{ 'confirmPassword' | translate }}</mat-label>
        <div class="position_relative">
          <mat-form-field appearance="fill">
            <input
              matInput
              placeholder="{{ 'EnterPassword' | translate }}"
              class="form_control"
              formControlName="confirmPassword"
              [type]="hiddenConfirmPasswordVisibility ? 'password' : 'text'" />
          </mat-form-field>
          <span
            mat-icon-button
            matSuffix
            (click)="
              hiddenConfirmPasswordVisibility = !hiddenConfirmPasswordVisibility
            "
            [attr.aria-mat-label]="'Hide password'"
            [attr.aria-pressed]="hiddenConfirmPasswordVisibility"
            class="pass_ico">
            <mat-icon>{{
              hiddenConfirmPasswordVisibility ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </span>
          @if (
            submitted &&
            setPasswordFormGroup.controls['confirmPassword'].hasError(
              'required'
            )
          ) {
            <mat-error>
              {{ 'confirmPassword' | translate }} {{ 'isRequired' | translate }}
            </mat-error>
          }
          @if (
            submitted &&
            setPasswordFormGroup.controls['confirmPassword'].hasError(
              'mustMatch'
            )
          ) {
            <mat-error>
              {{ 'confirmAndPasswordSame' | translate }}
            </mat-error>
          }
        </div>
      </div>
      <div class="submit_btn">
        <button class="btn btn_primary w_100" type="submit">
          {{ 'Submit' | translate }}
        </button>
      </div>
    </form>
</div>


