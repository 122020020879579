import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { ToasterService } from '../../services/toaster.service';

export const AuthGuardFxn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  const _authService = inject(AuthService);
  const router = inject(Router);
  const _toasterService = inject(ToasterService);
  const _translateService = inject(TranslateService);
  const platformId = inject(PLATFORM_ID);

  if (state.url.includes('bookings')) {
    if (!_authService.isAuthenticated()) {
      _authService.isLoggedIn.next(false);
      if (isPlatformBrowser(platformId)) {
        _authService.openLoginModal();
        _toasterService.infoToastr(_translateService.instant('LoginFirst'));
      }
      return false;
    }
    return true;
  } else {
    if (_authService.isAuthenticated()) {
      router.navigate(['/bookings']);
      return false;
    }
    return true;
  }

};
