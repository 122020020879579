import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { assets } from '../core/const/assetsConst';
import { BaseClass } from '../shared/class/baseClass';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'xplora-luxe-restaurant-verification',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './restaurant-verification.component.html',
  styleUrl: './restaurant-verification.component.scss'
})
export class RestaurantVerificationComponent extends BaseClass implements OnInit, OnDestroy {
  restaurantId: string | null = null;
  destroy$: Subject<boolean> = new Subject<boolean>();
  protected assetConst = assets;
  constructor(
    private _authService: AuthService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.restaurantId = this.route.snapshot.paramMap.get('id');
  }

  confirmJoin(): void {
    if (this.restaurantId) {
      const payload = {
        restaurantRequestId: this.restaurantId
      };
      this.spinnerService.addToLoader('restaurantVerification');
      this._authService.sendCredentials(payload).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            this.spinnerService.removeFromLoader('restaurantVerification');
            if (res) {
              this.toasterService.successToastr(
                this.translateService.instant('CredentialsSentSuccessfully')
              );
              this.router.navigate(['/login']);
            } else {
              this.spinnerService.removeFromLoader('restaurantVerification');
              this.toasterService.errToastr(
                this.translateService.instant('FailedToSendCredentials')
              );
            }
          },
          error: () => {
            this.spinnerService.removeFromLoader('restaurantVerification');
            this.toasterService.errToastr(
              this.translateService.instant('ErrorOccured')
            );
          }
        });
    } else {
      this.spinnerService.removeFromLoader('restaurantVerification');
      this.toasterService.errToastr(
        this.translateService.instant('InvalidId')
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

